import React, {useEffect, useState} from 'react';
import '../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../helper/apiClient';
import UserMenu from '../components/UserMenu';
import Footer from '../components/Footer';

function HandleBookingPage() {

  const { identifier, temporaryId } = useParams();
  const [template, setTemplate] = useState(null);
  const [status, setStatus] = useState('');
  const [urlStatus, setUrlStatus] = useState('');
  const { search } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const statusFromUrl = params?.get('status');
    if (statusFromUrl) {
        if(statusFromUrl === 'cancel' || statusFromUrl === 'accept') {
          setUrlStatus(statusFromUrl);
        }
    }
}, [search]);


useEffect(() => {
    const checkBookingTemporaryId = async () => {
      setLoading(true); // Ensure loading starts when API call begins
      try {
        const response = await apiClient.get(`/api/check/booking`, {
          params: { temporaryId, status: urlStatus },
        });

        if (response.status === 200) {
          const responseStatus = response?.data?.result;

          setStatus(responseStatus)
     
        } else {
          setStatus('error')
          // toast.error('Hiba: Nem található a foglalás.');
        }
      } catch (error) {
        if (error.response) {
          // console.error('Error fetching booking:', error.response.data);
          toast.error('Hiba: Sikertelen kérés.');
          // toast.error(`Hiba: ${error.response.data.error || 'Sikertelen kérés.'}`);
        } else {
          // console.error('Error fetching booking:', error);
          toast.error('Hiba: Kapcsolódási probléma.');
        }
      } finally {
        setLoading(false); // Stop loading after the API call
      }
    };

    if (temporaryId && urlStatus) {
        checkBookingTemporaryId();
    } else {
      setLoading(false); // Skip loading if temporaryId is not available
    }
  }, [temporaryId, urlStatus]);

  useEffect(() => {

    const getTemplate = async () => {
      try {
          const response = await apiClient.get(`/api/template`, {
            params: { identifier }, // Use params to construct the query string
          });
  
          const data = response.data;
          setTemplate(data);
  
      } catch (error) {
        navigate('/register?id=' + identifier);
        // console.error('Error fetching user template:', error);
      }
  };
      
    getTemplate();
    setLoading(false);

  }, [identifier]);

  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className='user-page-80'
        style={{ background: template?.background_color, color: template?.dark_color}}
      >
        <UserMenu template={template} identifier={identifier} />

        <div
            className={`border border-[--dark-color] text-center shadow-6xl xs:rounded-lg rounded-none mt-32 w-full xs:w-fit p-10 text-lg`}
            style={{background: template?.secondary_color, '--dark-color': template?.dark_color}}
        >
          TEST
          <div className='text-xl font-bold flex items-center'>
            {status === 'accept' &&
              <>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
              <span className='ml-8'>Sikeresen elfogadtad a foglalást.</span>
              </>
            }
            {status === 'accepted' &&
              <>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
              <span className='ml-8'>Ezt a foglalást már elfogadtad.</span>
              </>
            }

            {status === 'cancel' &&
              <>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
              <span className='ml-8'>Elutasítottad a foglalást.</span>
              </>
            }
            {status === 'cancelled' &&
              <>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
              <span className='ml-8'>Ezt a foglalást már elutasítottad.</span>
              </>
            }

            {status === 'error' &&
              <>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
              <span className='ml-8'>Nem található ilyen foglalás.</span>
              </>
            }
          </div>
        </div>
      </div>
      <Footer template={template} />
    </>
  )
}

export default HandleBookingPage;