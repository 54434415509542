import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import useCheckLoggedIn from "../../helper/useCheckLoggedIn";
import AdminNavBar from "../../components/AdminNavBar";
import apiClient from '../../helper/apiClient';
import EventEditor from "../../components/editor/EventEditor";
import Footer from "../../components/Footer";
import { useNavigate } from 'react-router-dom';

const EditEventPage = () => {

    const navigate = useNavigate();
    const { identifier, eventId } = useParams();
    const user = Cookies.get('uuid') || null;
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState(null);

    const checkLoggedIn = useCheckLoggedIn();
    useEffect(() => {
        checkLoggedIn(identifier);
        setLoading(false);
    }, [identifier]);

    useEffect(() => {
        const getEvent = async () => {
            try {
                const response = await apiClient.get(`/api/event/admin`, {
                    params: { eventId, identifier },
                });
                setEvent(response.data);

            } catch (error) {
                if (error.response) {
                    // console.error('Error fetching event:', error.response);
                    navigate(`/${identifier}/events`, { replace: true });
                } else {
                    // console.error('Error fetching event:', error);
                    navigate(`/${identifier}/events`, { replace: true });
                }
            } finally {
                setLoading(false);
            }
        };
        if(eventId) {
            getEvent();
        }
        setLoading(false);
    
      }, [eventId && identifier]);

    if (loading) {
    // if (loading && !event) {
        return (
            <div className='flex justify-center min-h-50-screen'>
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
    <>
        <div className='admin-page'>
            <AdminNavBar />
            <div className='px-4'>
                {event && <EventEditor event={event} identifier={identifier} userId={user} />}
            </div>
        </div>
        <Footer />
    </>
    )
}

export default EditEventPage