import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import apiClient from '../helper/apiClient';
import UserMenu from '../components/UserMenu';
import Footer from '../components/Footer';

const UserHomePage = () => {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { identifier } = useParams();

  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState(null);
  const [template, setTemplate] = useState(null);


  useEffect(() => {

    const getFields = async () => {
      try {
        const response = await apiClient.get(`/api/fields`, {
          params: { identifier },
        });

        if (response.status === 404) {
          navigate('/register?id=' + identifier);
          return;
        }

        setFields(response.data);
      } catch (error) {
        navigate('/register?id=' + identifier);
        // if (error.response) {
        //   console.error('Error fetching user fields:', error.response);
        // } else {
        //   console.error('Error fetching user fields:', error);
        // }
      }
    };
            
    const getTemplate = async () => {
      try {
          const response = await apiClient.get(`/api/template`, {
            params: { identifier },
          });

          const data = response.data;
          setTemplate(data);

      } catch (error) {
        navigate('/register?id=' + identifier);
        // console.error('Error fetching user template:', error);
      }
    };
            
    getTemplate() 
    getFields()
    setLoading(false);

  }, [identifier]);


  if (loading) {
      return (
          <div className='flex justify-center min-h-50-screen'>
              <span className="loading loading-spinner loading-lg"></span>
          </div>
      );
  }


  return (
  <>
    <div
        className='user-page pb-40'
        style={{ background: template?.background_color, color: template?.dark_color}}
    >
      <UserMenu template={template} identifier={identifier} />
            
      {(fields?.image || fields?.title) && (
        <div
          className={`
            relative flex items-center md:flex-row w-full p-10 flex-col min-h-120 bg-[--secondary-color]
          `}
          style={{'--secondary-color': template?.secondary_color}}

        >
          <div className="relative flex flex-col md:flex-row w-full items-center">
            {fields?.image && (
              <div
                className={`${
                  fields?.title && 'mb-4'
                } my-0 mx-auto rounded-xl min-w-56 min-h-56 w-56 h-56 bg-cover bg-center`}
                style={{ backgroundImage: `url(${apiBaseUrl + fields?.image})` }}
              />
            )}
            <div className="flex flex-col text-left md:ml-8 p-8 bg-white/80 rounded-xl">
              {fields?.title && (
                <div className="text-center md:text-left text-4xl font-bold mb-8">
                  {fields?.title}
                </div>
              )}
              {fields?.shortion && (
                <div className="text-center md:text-left text-base">
                  {fields?.shortion}
                </div>
              )}
              <button style={{ '--hover-color': template?.secondary_color, '--bgd-color': template?.primary_color}}
                  className="btn text-base btn-outline w-fit hover:bg-[--hover-color] bg-[--bgd-color] mt-8"
                  onClick={() => navigate(`/${identifier}/events`, { replace: true })}
              >
                  Időpontfoglalás
              </button>
            </div>
          </div>
        </div>
      )}

      {fields?.introduction &&
        <div
            className={`border border-[--dark-color] container shadow-6xl sm:rounded-lg rounded-none mt-8 w-full p-10 ql text-lg`}
            style={{background: template?.secondary_color, '--dark-color': template?.dark_color}}
        >
            {fields?.introduction && <div dangerouslySetInnerHTML={{ __html: fields?.introduction }} />}
        </div>
      }
    </div>

    <Footer template={template} />
  </>
  )
}

export default UserHomePage