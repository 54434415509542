import React from 'react'
import { useNavigate } from 'react-router-dom';
import formatNumberWithSpaces from '../helper/formatNumberWithSpaces';

  
const UserEventMiniBlock = ({ event, template, identifier }) => {
    const navigate = useNavigate();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    return (
    <>
        <div className="card card-compact shadow-6xl" style={{background: template?.light_color}}>
            <figure>
                <div
                    className="my-0 mx-auto w-full h-40 bg-cover bg-center"
                    style={{
                    backgroundImage: `url(${apiBaseUrl + event?.image})`,
                    }}
                />
            </figure>

            <div className="card-body">
                <div className='flex text-xl font-bold'>
                    <div className='text-cut'>{event?.label}</div>
                </div>
                {/* {event.description &&
                <>
                <hr />
                <div className='flex text-base'>
                    {event?.description && <div dangerouslySetInnerHTML={{ __html: event?.description }} />}
                </div>
                </>} */}
                <hr />
                <div className='flex flex-col'>
                    <div className='text-xs font-bold'>Időtartam:</div>
                {(event?.hour || event?.minute) ?
                    <div className='text-base'>{event?.hour} óra {event?.minute} perc</div>
                :
                    ''
                }
                </div>
                {/* {event.headcount &&
                <>
                <hr />
                <div className='flex flex-col'>
                    <div className='text-xs font-bold'>Maximális létszám:</div>
                    <div className='text-base'>{event?.headcount}</div>
                </div>
                </>} */}
                {event?.price &&
                <>
                <hr />
                <div className='flex flex-col'>
                    <div className='text-xs font-bold'>Ár:</div>
                    <div className='text-base'>{formatNumberWithSpaces(event?.price)} Ft</div>
                </div>
                </>}
            </div>
            

            <div className="card-actions justify-center p-4">
                <button style={{ '--hover-color': template?.secondary_color, '--bgd-color': template?.primary_color}}
                    className="btn btn-outline w-full hover:bg-[--hover-color] bg-[--bgd-color]"
                    onClick={() => navigate(`/${identifier}/event/${event?.id}`)}
                >
                    Időpontfoglalás
                </button>
            </div>
        </div>
    </>
    )
}

export default UserEventMiniBlock