import React, { useState, useEffect } from "react";
import toMariaDBTimestamp from "../helper/toMariaDBTimestamp";

const Calendar = ({ setEventdateId, setEventDate, maxHeadcount, eventDates, template, setIsDateSelected }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [bookableDays, setBookableDays] = useState([]);
  const [eventsForSelectedDate, setEventsForSelectedDate] = useState([]);

  const daysOfWeek = ["H", "K", "Sze", "Cs", "P", "Szo", "V"];

  // useEffect(() => {
  //   if (selectedDate && eventDates) {
  //       setIsDateSelected(false);
  //       setSelectedTime(null)
  //     const selectedDay = selectedDate.getDate();
  //     const selectedMonth = selectedDate.getMonth() + 1; // Get the month in correct format (1-12)
  //     const selectedYear = selectedDate.getFullYear();
  
  //     // Filter eventDates to find those that match the selected date (year, month, day)
  //     const filteredEventDates = eventDates.filter((eventDate) => {
  //       const [year, month, day] = eventDate.date.split("-").map(Number);
  //       return (
  //         year === selectedYear &&
  //         month === selectedMonth &&
  //         day === selectedDay &&
  //         eventDate.booking_count < maxHeadcount
  //       );
  //     });

  //     // Sort events first by start_hour, then by start_minute
  //     const sortedEvents = filteredEventDates.sort((a, b) => {
  //       if (a.start_hour === b.start_hour) {
  //         return a.start_minute - b.start_minute; // If hours are the same, sort by minutes
  //       }
  //       return a.start_hour - b.start_hour; // Otherwise, sort by hour
  //     });
  
  //     // Set filtered and sorted events for rendering
  //     setEventsForSelectedDate(sortedEvents);
  //   }
  // }, [selectedDate, eventDates]);


  useEffect(() => {
    if (selectedDate && eventDates) {
      setIsDateSelected(false);
      setSelectedTime(null);
  
      const selectedDay = selectedDate.getDate();
      const selectedMonth = selectedDate.getMonth() + 1; // Get the month in correct format (1-12)
      const selectedYear = selectedDate.getFullYear();
  
      const now = new Date(); // Current date and time
  
      // Filter eventDates to find those that match the selected date (year, month, day)
      const filteredEventDates = eventDates.filter((eventDate) => {
        const [year, month, day] = eventDate.date.split("-").map(Number);
  
        // Create a Date object for the event's start time
        const eventDateTime = new Date(year, month - 1, day, eventDate.start_hour, eventDate.start_minute);
  
        // Check if the event matches the selected date and is in the future
        return (
          year === selectedYear &&
          month === selectedMonth &&
          day === selectedDay &&
          eventDate.booking_count < maxHeadcount &&
          eventDateTime > now // Ensure the event is in the future
        );
      });
  
      // Sort events first by start_hour, then by start_minute
      const sortedEvents = filteredEventDates.sort((a, b) => {
        if (a.start_hour === b.start_hour) {
          return a.start_minute - b.start_minute; // If hours are the same, sort by minutes
        }
        return a.start_hour - b.start_hour; // Otherwise, sort by hour
      });
  
      // Set filtered and sorted events for rendering
      setEventsForSelectedDate(sortedEvents);
    }
  }, [selectedDate, eventDates]);
  

  useEffect(() => {
    if (selectedTime && selectedDate) {
        setIsDateSelected(true);
        setEventDate(`${toMariaDBTimestamp(selectedDate)} ${selectedTime}`);        
    }
  }, [selectedTime]);
  

  // useEffect(() => {
  //   if (eventDates?.length) {
  //     console.log('eventDates', eventDates)
  //     const bookables = eventDates
  //       .filter((event) => {
  //         const [year, month] = event.date.split("-").map(Number);
  //         return year === currentYear && month - 1 === currentMonth; // Match year and month
  //       })
  //       .map((event) => Number(event.date.split("-")[2])); // Get the day
  //       console.log('bookables', bookables)
  //     setBookableDays(bookables);
  //   }
  // }, [eventDates, currentYear, currentMonth]);

  useEffect(() => {
    if (eventDates?.length) {
      const now = new Date();
  
      const bookables = eventDates
        .filter((event) => {
          const [year, month, day] = event.date.split("-").map(Number);
          const eventDateTime = new Date(year, month - 1, day, event.start_hour, event.start_minute);
  
          // Ensure the event's year and month match the currentYear and currentMonth,
          // and check that the event's datetime is not in the past.
          return (
            year === currentYear &&
            month - 1 === currentMonth &&
            eventDateTime > now
          );
        })
        .map((event) => Number(event.date.split("-")[2])); // Extract the day as a number
      setBookableDays(bookables);
    }
  }, [eventDates, currentYear, currentMonth]);

  


  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const generateCalendar = () => {
    const days = [];
    const numDays = daysInMonth(currentYear, currentMonth);

    // Adjust for first day of the month (to start from Monday)
    const leadingEmptyDays = (firstDayOfMonth + 6) % 7;
    for (let i = 0; i < leadingEmptyDays; i++) {
      days.push(null);
    }

    for (let day = 1; day <= numDays; day++) {
      days.push(day);
    }

    return days;
  };

  const handleDayClick = (day) => {
    if (day) {
      setSelectedDate(new Date(currentYear, currentMonth, day));
    }
  };

  const handleMonthChange = (direction) => {
    setCurrentMonth((prevMonth) => {
      let newMonth = prevMonth + direction;
      if (newMonth < 0) {
        setCurrentYear((prevYear) => prevYear - 1);
        newMonth = 11; // December
      } else if (newMonth > 11) {
        setCurrentYear((prevYear) => prevYear + 1);
        newMonth = 0; // January
      }
      return newMonth;
    });
  };

  const handleYearChange = (direction) => {
    setCurrentYear((prevYear) => prevYear + direction);
  };

  const days = generateCalendar();

  return (
    <div className="max-w-sm mx-auto mt-10">
      <div className="grid grid-cols-7 gap-2 text-center">
        {/* Year Navigation */}
        <div className="col-span-7 flex items-center justify-between">
          <button
            style={{
              "--hover-color": template?.primary_color,
              "--bg-color": template?.secondary_color,
            }}
            className="btn btn-outline p-1 min-h-10 h-10 bg-[--bg-color] hover:bg-[--hover-color]"
            onClick={() => handleYearChange(-1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 17l-5-5 5-5M18 17l-5-5 5-5"/></svg>
          </button>
          <div className="text-xl font-bold">{currentYear}</div>
          <button
            style={{
              "--hover-color": template?.primary_color,
              "--bg-color": template?.secondary_color,
            }}
            className="btn btn-outline p-1 min-h-10 h-10 bg-[--bg-color] hover:bg-[--hover-color]"
            onClick={() => handleYearChange(1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M13 17l5-5-5-5M6 17l5-5-5-5"/></svg>
          </button>
        </div>

        {/* Month Navigation */}
        <div className="col-span-7 flex items-center justify-between">
          <button
            style={{
              "--hover-color": template?.primary_color,
              "--bg-color": template?.secondary_color,
            }}
            className="btn btn-outline p-1 min-h-10 h-10 bg-[--bg-color] hover:bg-[--hover-color]"
            onClick={() => handleMonthChange(-1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 18l-6-6 6-6"/></svg>
          </button>
          <div className="text-lg font-semibold">
            {new Date(currentYear, currentMonth).toLocaleString("hu", {
              month: "long",
            })}
          </div>
          <button
            style={{
              "--hover-color": template?.primary_color,
              "--bg-color": template?.secondary_color,
            }}
            className="btn btn-outline p-1 min-h-10 h-10 bg-[--bg-color] hover:bg-[--hover-color]"
            onClick={() => handleMonthChange(1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 18l6-6-6-6"/></svg>
          </button>
        </div>

        {/* Days of the Week */}
        {daysOfWeek.map((day, index) => (
          <div key={index} className="font-medium">
            {day}
          </div>
        ))}

        {/* Calendar Days */}
        {days.map((day, index) => {
          const today = new Date();
          const isToday =
            day === today.getDate() &&
            currentMonth === today.getMonth() &&
            currentYear === today.getFullYear();
          const isSelected =
            selectedDate &&
            day === selectedDate.getDate() &&
            currentMonth === selectedDate.getMonth() &&
            currentYear === selectedDate.getFullYear();
          const isBookable = bookableDays.includes(day);
            // Create a date object for the current day
          const dayDate = new Date(currentYear, currentMonth, day);
          // Check if the day is in the past
          const isPast = dayDate < today;

          return (
            <div
              key={index}
              style={{
                "--light-color": template?.light_color,
                "--selected-color": template?.primary_color,
                "--today-color": template?.secondary_color,
              }}
              className={`indicator p-2 min-h-8 h-8 min-w-8 sm:p-4 sm:min-w-13 sm:min-h-12 sm:h-12 ${
                isSelected
                  ? "bg-[--selected-color]"
                  : isToday
                  ? "bg-[--today-color]"
                  : "bg-[--light-color]"
              } ${day ? "btn btn-outline hover:bg-[--selected-color]" : ""}
              ${!isBookable ? "btn-disabled" : ""}
              `}
              // ${(!isBookable || isPast) && day ? "btn-disabled" : ""}
              // ${(isPast) && day ? "btn-disabled" : ""}
              // className={`indicator p-2 min-h-8 h-8 min-w-8 sm:p-4 sm:min-w-13 sm:min-h-12 sm:h-12 ${
              //   isSelected
              //     ? "bg-[--selected-color]"
              //     : isToday
              //     ? "bg-[--today-color]"
              //     : "bg-[--light-color]"
              // } ${day ? "btn btn-outline hover:bg-[--selected-color]" : ""}
              // ${(!isBookable || isPast) && day ? "btn-disabled" : ""}
              // `}
              // ${(isPast) && day ? "btn-disabled" : ""}
              onClick={() => handleDayClick(day)}
            >
            {/* {isHighlighted && !isPast && <span 
              style={{
                "--selected-color": template?.primary_color,
              }}
              className="indicator-item badge border border-[--selected-color] bg-[--selected-color]"></span>} */}
              {day || ""}
            </div>
          );
        })}
      </div>

{/* Render events for the selected day */}
{selectedDate && (
    <div className="mt-8">
        {eventsForSelectedDate.length > 0 && (
            <>
                <div className="text-lg font-bold mb-2">{toMariaDBTimestamp(selectedDate)}</div>
                <ul className="flex flex-wrap gap-2">
                    {eventsForSelectedDate
                        .sort((a, b) => new Date(a?.date) - new Date(b?.date))
                        .map((dateItem, index) => (
                          <li key={index}>
                          <div
                              className={`btn btn-outline w-24 cursor-pointer ${
                                  `${String(dateItem?.start_hour).padStart(2, '0')}:${String(dateItem?.start_minute).padStart(2, '0')}` === selectedTime
                                      ? "bg-[--selected-color]"
                                      : "bg-[--bgd-color]"
                              } hover:bg-[--selected-color]`}
                              style={{
                                  '--bgd-color': template?.secondary_color,
                                  '--selected-color': template?.primary_color,
                              }}
                              onClick={() => {
                                setEventdateId(dateItem?.uuid);
                                setSelectedTime(
                                  `${String(dateItem?.start_hour).padStart(2, '0')}:${String(dateItem?.start_minute).padStart(2, '0')}`
                                )
                              }
                            }
                          >
                              <div>
                                  {String(dateItem?.start_hour).padStart(2, '0')}:
                                  {String(dateItem?.start_minute).padStart(2, '0')}
                              </div>
                          </div>
                      </li>
                      
                        ))}
                </ul>
            </>
        )}
    </div>
)}


        {/* Render events for the selected day */}
        {/* {selectedDate && (
            <div className="mt-8">
            {eventsForSelectedDate.length > 0 && (
                <>
                <div className="text-lg font-bold mb-2">{toMariaDBTimestamp(selectedDate)}</div>
                <ul className="flex flex-wrap gap-2">
                {eventsForSelectedDate
                    .sort((a, b) => new Date(a?.date) - new Date(b?.date))
                    .map((dateItem, index) => (
                    <li key={index}>
                        <input
                        type="radio"
                        id={`event-date-${index}`}
                        name="event-date"
                        value={dateItem?.date}
                        className="hidden peer"
                        required
                        onClick={() => setIsDateSelected(true)}
                        />
                        <label
                        htmlFor={`event-date-${index}`}
                        className={`btn btn-outline w-24 bg-[--bgd-color] hover:bg-[--selected-color] peer-checked:bg-[--selected-color]`}
                        style={{ '--bgd-color': template?.secondary_color, '--selected-color': template?.primary_color }}
                        >
                        <div>
                            {String(dateItem?.start_hour).padStart(2, '0')}:
                            {String(dateItem?.start_minute).padStart(2, '0')}
                        </div>
                        </label>
                    </li>
                    ))}
                </ul>
                </>
            )}
            </div>
        )} */}
    </div>
  );
};


export default Calendar;
